<template>
  <v-card>
    <v-card-title>
      <div v-if="readonly">Profile</div>
      <div v-else>Edit Profile</div>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="user.name"
          label="Name"
          v-validate="'required'"
          required 
          data-vv-name="first name"
          :error-messages="errors.collect('name')"
          :readonly="readonly"
        ></v-text-field>

        <v-text-field
          v-model="user.email"
          label="E-mail"
          v-validate="'required|email'"
          required
          data-vv-name="email"
          :error-messages="errors.collect('email')"
          disabled
        ></v-text-field>

        <v-text-field
          v-model="user.countryCode"
          label="Country Code"
          data-vv-name="Country Code"
          :error-messages="errors.collect('phone')"
          :readonly="readonly"
          v-validate="{regex:/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i}"
        ></v-text-field>

        <v-text-field
          v-model="user.mobileNumber"
          label="Mobile Number"
          data-vv-name="mobileNumber"
          :error-messages="errors.collect('phone')"
          :readonly="readonly"
          v-validate="{regex:/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i}"
        ></v-text-field>

        <!-- <v-text-field
          v-model="user.profile.oldPassword"
          label="Password"
          :type="'password'"
          v-validate="'required|min:8'"
          required
          data-vv-name="password"
          :error-messages="errors.collect('password')"
          :readonly="readonly"
        ></v-text-field>

        <v-text-field
          v-model="user.profile.newPassword"
          label="New Password"
          :type="'password'"
          v-validate="'required|min:8'"
          required
          data-vv-name="newPassword"
          :error-messages="errors.collect('newPassword')"
          :readonly="readonly"
        ></v-text-field> -->

        <v-text-field v-if="organization && organization.name"
          v-model="organization.name"
          label="Organization"
          disabled
        ></v-text-field>

        <!-- <v-text-field v-if="user.department"
          v-model="user.department"
          label="Department"
          disabled
        ></v-text-field> -->

        <!-- <v-btn v-show="editProfile"
          :disabled="!valid"
          color="primary"
          @click.prevent="validate"
          :readonly="readonly"
        >Save</v-btn>

        <v-btn v-show="!editProfile"
          color="primary"
          @click.prevent="toggleEditProfile"
        >Edit Profile</v-btn> -->

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      editProfile: false,
      valid: true,
      user: this.$store.state.userProfile,
      organization: this.$store.state.organization,
      readonly: true
    }
  },
  methods: {
    async validate () {
      // let v = await this.$validator.validateAll();
      // if (this.$refs.form.validate()) {
      //   this.snackbar = true
      //   this.editProfile = !this.editProfile
      // }
    },    
    toggleEditProfile() {
      this.editProfile = !this.editProfile
      this.readonly = !this.readonly
    },
  }
}
</script>